import React from 'react';

const NavigationDots = ({ active }) => (
  <div className="app__navigation">
    {['početna', 'info', 'vanjsko Oglašavanje', 'Promjena Vizuala', 'Iskopi', 'Partneri', 'Kontakt'].map((item, index) => (
      <a
        href={`#${item}`}
        key={item + index}
        className="app__navigation-dot"
        style={active === item ? { backgroundColor: '#f48915' } : {}}
      >
      </a>
    ))}
  </div>
);

export default NavigationDots;