import React, { useState, useEffect } from 'react';
import './Header.css';
import { images } from '../../constants';

const Header = () => {
  const [menuOpened, setMenuOpened] = useState(false);
  const [mobile, setMobile] = useState(window.innerWidth <= 768); // Početno stanje za mobilni uređaj

  // UseEffect za dinamičko praćenje veličine prozora
  useEffect(() => {
    const handleResize = () => {
      setMobile(window.innerWidth <= 768); // Ažuriranje stanja prema veličini prozora
    };

    window.addEventListener('resize', handleResize); // Dodavanje event listener-a za resize
    return () => window.removeEventListener('resize', handleResize); // Čišćenje listenera nakon što komponenta bude unmounted
  }, []);

  return (
    <div className="header">
      {/* Ako nije otvoren meni i ako je mobilni uređaj, prikaži ikonu za meni */}
      {menuOpened === false && mobile ? (
        <div
          style={{
            backgroundColor: 'var(--appColor)',
            padding: '0.5rem',
            borderRadius: '5px',
          }}
        >
          <img
            src={images.bars}
            alt="menu icon"
            style={{ width: '1.5rem', height: '1.5rem' }}
            onClick={() => setMenuOpened(true)} // Otvoriti meni na klik
          />
        </div>
      ) : (
        <ul className="header__links">
          {['početna', 'info', 'vanjsko Oglašavanje', 'Promjena Vizuala', 'Iskopi', 'Partneri', 'Kontakt'].map((item) => (
            <li className="links" key={`link-${item}`} onClick={() => setMenuOpened(false)}>
              <div />
              <a href={`#${item}`}>{item}</a>
            </li>
          ))}
        </ul>
      )}
      {/* Prikazivanje loga */}
      <img src={images.redLogo} alt="logo" className="logo" />
    </div>
  );
};

export default Header;
