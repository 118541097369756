import React, { useState, useEffect } from "react";
import './Advertising.css';
import { AppWrap } from '../../wrapper';
import { createClient } from 'contentful';

import { FaCaretSquareDown, FaDirections } from "react-icons/fa";
import { AiOutlineFullscreen } from "react-icons/ai";
import { HiOutlineLightBulb } from "react-icons/hi";
import { TiArrowUnsorted } from "react-icons/ti";


const client = createClient({
  space: '7lkluz6ffmt3',
  accessToken: '2gkj1YKqTPvQ4bjZMcvbldW2RFiHiWpHTJW_Yw2YsUU',
});

const Advertising = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await client.getEntries({
        content_type: 'city',
      });
      setData(response.items);
    };
    fetchData();
  }, []);
  console.log(data)

  return (
    <div>
      <div className='Advertising' id='advertising'>
        <div className='Offers__header'>
          <span className='stroke-text'>Vanjsko</span>
          <span>oglašavanje</span>
        </div>
      </div>

      <div className='Advertising__cards'>
      
      
      {data.map((item) => (
        <div className='Advertising__card' key={item.sys.id}>
          <div className='Advertising__card-image'>
            <img 
              src={item.fields.image.fields.file.url}
              alt={item.fields.image.fields.file.fileName}
            />  
          </div>
          <div className='Advertising__card-content'>
            <h3>{item.fields.grad}</h3>
            <p>{item.fields.info}</p>
            <p>
              <span><TiArrowUnsorted/></span>
              <span>REKLAMNE POVRŠINE :</span>
              {item.fields.kindOf}
            </p>
            <p>
              <span><AiOutlineFullscreen/></span><span>DIMENZIJE :</span>{item.fields.dimensions}</p>
            <p><span><HiOutlineLightBulb/></span><span>OSVJETLJENJE :</span>{item.fields.lighting ? 'Yes' : 'No'}</p>
            {item.fields.locations && (
              <div>
                <h3><span><AiOutlineFullscreen/></span>LOKACIJA:</h3>
                {item.fields.locations.map((location, index) => (
                  <p key={index}>Lat: {location.lat}, Lng: {location.lon}</p>
                ))}
              </div>
            )}
          </div>
        </div>
      ))}
      </div>

    </div>
  )
}


export default AppWrap(Advertising, 'vanjsko Oglašavanje')