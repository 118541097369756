import logo from "../assets/LogoDugi.png";
import heart from "../assets/heart.png";
import hero_image from "../assets/hero_image.png";
import hero_image_back from "../assets/hero_image_back.png";
import calories from "../assets/calories.png";
import bilboard from "../assets/bilboard2transparent.png";
import rightArrow from "../assets/rightArrow.png";
import web from "../assets/web.png";
import webSvg from "../assets/webPlus.svg";
import whiteTick from "../assets/whiteTick.png";
import leftArrow from "../assets/leftArrow.png";
import github from "../assets/github.png";
import instagram from "../assets/instagram.png";
import linkedin from "../assets/linkedin.png";
import red_logo from "../assets/22Red.png";
import b2 from "../assets/b2.png";
import bars from "../assets/bars.png";

import advertisment from "../assets/advertisment.png";
import lookingFor from "../assets/lookingFor.png";
import programming from "../assets/programming.png";

import image1 from "../assets/image1.png";
import image2 from "../assets/image2.png";
import image3 from "../assets/image3.png";
import image4 from "../assets/image4.png";
import image5 from "../assets/t-image4.jpg";

import tick from "../assets/tick.png";

import lidl from "../assets/partners/lidl.png";
import plodine from "../assets/partners/plodine.png";
import konzum from "../assets/partners/konzum.png";
import crosig from "../assets/partners/crosig.jpg";
import papirus from "../assets/partners/papirus.jpg";
import cistoca from "../assets/partners/cistoca.png";
import pan from "../assets/partners/pan.jpg";

import sirobujaA from "../assets/reklame/sirobujaA.jpg";
import redLogo from "../assets/redLogo.png";
import gbb from "../assets/gbb.png";

export default {
  sirobujaA,
  gbb,
  bars,
  redLogo,
  b2,
  red_logo,
  github,
  linkedin,
  instagram,
  image5,
  leftArrow,
  whiteTick,
  pan,
  lidl,
  plodine,
  konzum,
  crosig,
  papirus,
  cistoca,
  tick,
  image1,
  image2,
  image3,
  image4,
  advertisment,
  lookingFor,
  programming,
  webSvg,
  web,
  rightArrow,
  bilboard,
  calories,
  heart,
  hero_image,
  hero_image_back,
  logo,
};
