import React from 'react'
import "./Offers.css"
import {images} from "../../constants"
import {programsData} from "../../data/programsData"
import { AppWrap} from '../../wrapper';


const Offers = () => {
  return (
    <div className='Offers' id="offers">
    {/*Offers Header */}
      <div className='Offers__header'>
        <span className='stroke-text'>istražite</span>
        <span >naše</span>
        <span className='stroke-text'>ponude</span>
      </div>
      <div className='offers__programs'>
        {programsData.map((program)=>(
          <div className='offers'>
            {program.image}
            <span>{program.heading}</span>
            <span>{program.details}</span>
            <div className='join_now'>
              <span>Info</span>
              <img src={images.rightArrow} alt=""/>
            </div>
          </div>
        ))}
      
      
      </div>
    
    
    
    </div>
  )
}

//export default AppWrap (Offers, "offers");

export default AppWrap(Offers,'info');